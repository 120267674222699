import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/armeria/armeria/site/src/layouts/release-notes.tsx";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const ThankYou = makeShortcode("ThankYou");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p {...{
      "className": "date"
    }}>{`30th November 2020`}</p>


    <h2 {...{
      "id": "-new-features",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#-new-features",
        "aria-label": " new features permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`🌟 New features`}</h2>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`You can now use `}<a parentName="p" {...{
            "href": "https://unixism.net/loti/what_is_io_uring.html"
          }}>{`io_uring`}</a>{` for efficient I/O
processing in Linux. `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/3182"
          }}>{`#3182`}</a></p>
        <ul parentName="li">
          <li parentName="ul">{`Specify the `}<inlineCode parentName="li">{`-Dcom.linecorp.armeria.transportType=io_uring`}</inlineCode>{` JVM option to enable it.`}</li>
          <li parentName="ul">{`Netty's `}<inlineCode parentName="li">{`io_uring`}</inlineCode>{` transport is currently experimental, so you should be careful using the feature.`}</li>
        </ul>
      </li>
      <li parentName="ul">
        <p parentName="li">{`The metrics of requests to a `}<a parentName="p" {...{
            "href": "type://TransientService:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/TransientService.html"
          }}>{`type://TransientService`}</a>{` are not collected anymore by default. `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/3061"
          }}>{`#3061`}</a>{` `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/3081"
          }}>{`#3081`}</a></p>
        <ul parentName="li">
          <li parentName="ul">{`Access logs and service logs are not recorded as well.`}</li>
          <li parentName="ul">{`You should use `}<a parentName="li" {...{
              "href": "type://TransientServiceOption:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/TransientServiceOption.html"
            }}>{`type://TransientServiceOption`}</a>{` to enable them.`}
            <pre parentName="li"><code parentName="pre" {...{
                "className": "language-java"
              }}>{`HealthCheckService.builder()
                  .transientServiceOptions(TransientServiceOption.WITH_METRIC_COLLECTION,
                                           TransientServiceOption.WITH_SERVICE_LOGGING,
                                           TransientServiceOption.WITH_ACCESS_LOGGING)
                  .build();
`}</code></pre>
          </li>
          <li parentName="ul">{`Currently, `}<a parentName="li" {...{
              "href": "type://HealthCheckService:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/healthcheck/HealthCheckService.html"
            }}>{`type://HealthCheckService`}</a>{` and `}<a parentName="li" {...{
              "href": "type://PrometheusExpositionService:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/metric/PrometheusExpositionService.html"
            }}>{`type://PrometheusExpositionService`}</a>{` are
`}<a parentName="li" {...{
              "href": "typeplural://TransientService:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/TransientService.html"
            }}>{`typeplural://TransientService`}</a>{`.`}</li>
        </ul>
      </li>
      <li parentName="ul">
        <p parentName="li">{`You can now use Protobuf's `}<inlineCode parentName="p">{`Message`}</inlineCode>{` and `}<a parentName="p" {...{
            "href": "https://github.com/scalapb/ScalaPB"
          }}>{`ScalaPB's`}</a>{`
`}<inlineCode parentName="p">{`GeneratedMessage`}</inlineCode>{` as a request/response object in an annotated service. `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/3088"
          }}>{`#3088`}</a>{` `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/3124"
          }}>{`#3124`}</a>{` `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/3192"
          }}>{`#3192`}</a></p>
        <ul parentName="li">
          <li parentName="ul">{`Use `}<inlineCode parentName="li">{`armeria-protobuf`}</inlineCode>{`, `}<inlineCode parentName="li">{`armeria-scalapb_2.12`}</inlineCode>{` or `}<inlineCode parentName="li">{`armeria-scalapb_2.13`}</inlineCode>{` dependencies.`}</li>
          <li parentName="ul">{`See `}<a parentName="li" {...{
              "href": "/docs/advanced-scalapb#supporting-scalapb-in-annotated-services"
            }}>{`Supporting ScalaPB in annotated services`}</a>{`
for more information.`}</li>
        </ul>
      </li>
      <li parentName="ul">
        <p parentName="li">{`You can now use Scala `}<inlineCode parentName="p">{`Future`}</inlineCode>{` in an annotated service. `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/3189"
          }}>{`#3189`}</a></p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-scala"
          }}>{`@Get("/items/{id}")
def items(@Param id: Int)(implicit ec: ExecutionContext): Future[String] = {
  Future {
    // Perform asynchronous task using Armeria's event loop.
    ...
  }
}
`}</code></pre>
      </li>
      <li parentName="ul">
        <p parentName="li">{`You can now use `}<a parentName="p" {...{
            "href": "type://HttpDeframer"
          }}>{`type://HttpDeframer`}</a>{` to conveniently decode a stream of `}<a parentName="p" {...{
            "href": "typeplural://HttpObject:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/HttpObject.html"
          }}>{`typeplural://HttpObject`}</a>{`
to N objects. `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/2981"
          }}>{`#2981`}</a></p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java"
          }}>{`HttpDeframerHandler<String> decoder = ...
HttpDeframer<String> deframer = HttpDeframer.of(decoder, ByteBufAllocator.DEFAULT);
HttpRequest request = ...;
request.subscribe(deframer);
`}</code></pre>
        <ul parentName="li">
          <li parentName="ul">{`See `}<a parentName="li" {...{
              "href": "type://HttpDeframer"
            }}>{`type://HttpDeframer`}</a>{` for more information.`}</li>
        </ul>
      </li>
      <li parentName="ul">
        <p parentName="li">{`You can now apply `}<a parentName="p" {...{
            "href": "type://CircuitBreaker:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/circuitbreaker/CircuitBreaker.html"
          }}>{`type://CircuitBreaker`}</a>{` per request path. `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/3134"
          }}>{`#3134`}</a>{`, `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/3135"
          }}>{`#3135`}</a></p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java"
          }}>{`CircuitBreakerFactory factory = ...
// CircuitBreaker is applied per the combination of host and path.
CircuitBreakerMapping mapping = CircuitBreakerMapping.builder()
                                                     .perPath()
                                                     .perHost()
                                                     .build(factory);
CircuitBreakerRule rule = ...
CircuitBreakerClient.newDecorator(mapping, rule);
`}</code></pre>
      </li>
      <li parentName="ul">
        <p parentName="li">{`You can now apply the different `}<inlineCode parentName="p">{`maxTotalAttempts`}</inlineCode>{` and `}<inlineCode parentName="p">{`responseTimeout`}</inlineCode>{` for `}<a parentName="p" {...{
            "href": "type://RetryingClient:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/retry/RetryingClient.html"
          }}>{`type://RetryingClient`}</a>{`
using `}<a parentName="p" {...{
            "href": "type://RetryConfig:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/retry/RetryConfig.html"
          }}>{`type://RetryConfig`}</a>{`. `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/3145"
          }}>{`#3145`}</a></p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java"
          }}>{`BiFunction<ClientRequestContext, Request, String> keyFactory =
    (ctx, req) -> ctx.endpoint().host();
BiFunction<ClientRequestContext, Request, RetryConfig<HttpResponse>> configFactory = (ctx, req) -> {
    String host = ctx.endpoint().host();
    RetryConfigBuilder builder = RetryConfig.<HttpResponse>builder(RetryRule.onException());
    if (host.equals("host1")) {
        builder.maxTotalAttempts(2);
    } else if (host.equals("host2")) {
        builder.maxTotalAttempts(4);
    } else {
        builder.maxTotalAttempts(1);
    }
    return builder.build();
};
RetryConfigMapping mapping = RetryConfigMapping.of(keyFactory, configFactory);
RetryingClient.newDecoratorWithMapping(mapping);
`}</code></pre>
      </li>
      <li parentName="ul">
        <p parentName="li">{`You can now split the `}<a parentName="p" {...{
            "href": "type://ResponseHeaders:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/ResponseHeaders.html"
          }}>{`type://ResponseHeaders`}</a>{` and bodies using `}<a parentName="p" {...{
            "href": "type://HttpResponse#split():https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/HttpResponse.html#split()"
          }}>{`type://HttpResponse#split()`}</a>{`. `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/3038"
          }}>{`#3038`}</a></p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java"
          }}>{`HttpResponse response = ...
SplitHttpResponse splitHttpResponse = response.split();
CompletableFuture<ResponseHeaders> headersFuture = splitHttpResponse.headers();
StreamMessage<HttpData> bodyStream = splitHttpResponse.body();

headersFuture.thenApply(headers -> {
    if (headers.contentType() == MediaType.JSON_SEQ) {
        // Subscribe to a stream of HttpData.
        Flux.from(bodyStream)
            .map(httpData -> {
                // Convert HttpData to your domain object
            });
            ...
    }
});
`}</code></pre>
      </li>
      <li parentName="ul">
        <p parentName="li">{`You can now customize for mapping an exception to a gRPC status. `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/3197"
          }}>{`#3197`}</a></p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java"
          }}>{`GrpcService.builder()
           .addExceptionMapping(AccessDeniedException.class, Status.UNAUTHENTICATED);
// Or, use GrpcStatusFunction.
GrpcService.builder()
           .exceptionMapping(cause -> {
               if (cause instanceof AccessDeniedException) {
                   return Status.UNAUTHENTICATED;
               }
               if (cause instanceof FileNotFoundException) {
                   return Status.NOT_FOUND;
               }
               return null; // Return null to use Armeria's default exception mapping.
           });
`}</code></pre>
      </li>
      <li parentName="ul">
        <p parentName="li">{`You can now specify the Caffeine spec for the DNS resolver cache. `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/2970"
          }}>{`#2970`}</a>{` `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/3007"
          }}>{`#3007`}</a></p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`You can now specify a prefix for MDC keys using the `}<inlineCode parentName="p">{`<prefix>`}</inlineCode>{` element. `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/3086"
          }}>{`#3086`}</a>{` `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/3112"
          }}>{`#3112`}</a></p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-xml"
          }}>{`<configuration>
  ...
  <appender name="RCEA" class="com.linecorp.armeria.common.logback.RequestContextExportingAppender">
    ...
    <!-- set the prefix of exports which is not wrapped with the <exportGroup> element -->
    <prefix>armeria.</prefix>
    <export>remote.id</export>
    <export>req.headers.user-agent</export>
    ...
    <exportGroup>
      <!-- set the prefix of exports in this <exportGroup> -->
      <prefix>some_prefix.</prefix>
      <export>some_value=attr:com.example.AttrKeys#SOME_KEY</export>
      ...
    </exportGroup>
    <exportGroup>
      <!-- if <prefix> is not defined, no prefix is added to exports -->
      <export>tracking_id=attr:com.example.AttrKeys#TRACKING_ID_KEY</export>
      ...
    </exportGroup>
  </appender>
  ...
</configuration>
`}</code></pre>
      </li>
      <li parentName="ul">
        <p parentName="li">{`You can now use the unsafe TLS cipher using `}<a parentName="p" {...{
            "href": "type://ClientFactoryBuilder#tlsAllowUnsafeCiphers(boolean):https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/ClientFactoryBuilder.html#tlsAllowUnsafeCiphers(boolean)"
          }}>{`type://ClientFactoryBuilder#tlsAllowUnsafeCiphers(boolean)`}</a>{`.
`}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/3157"
          }}>{`#3157`}</a>{` `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/3172"
          }}>{`#3172`}</a></p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`You can now specify an arbitrary type for `}<a parentName="p" {...{
            "href": "type://@Header:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/annotation/Header.html"
          }}>{`type://@Header`}</a>{` and `}<a parentName="p" {...{
            "href": "type://@Param:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/annotation/Param.html"
          }}>{`type://@Param`}</a>{` if the
type has one of following static methods or the constructor. `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/2574"
          }}>{`#2574`}</a>{` `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/3143"
          }}>{`#3143`}</a>{` `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/3164"
          }}>{`#3164`}</a></p>
        <ul parentName="li">
          <li parentName="ul"><inlineCode parentName="li">{`public static T of(String) { ... }`}</inlineCode></li>
          <li parentName="ul"><inlineCode parentName="li">{`public static T valueOf(String) { ... }`}</inlineCode></li>
          <li parentName="ul"><inlineCode parentName="li">{`public static T fromString(String) { ... }`}</inlineCode></li>
          <li parentName="ul"><inlineCode parentName="li">{`public T(String) { ... } // constructor`}</inlineCode></li>
        </ul>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java"
          }}>{`public class UserService {

    @Get("/api")
    public HttpResponse get(@Param User user) {
        ...
    }

    private static class User {
        User(String userId) { ... } // This constructor is used to create User.
        ...
    }
}
`}</code></pre>
      </li>
      <li parentName="ul">
        <p parentName="li">{`You can now build and execute an `}<a parentName="p" {...{
            "href": "type://HttpRequest:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/HttpRequest.html"
          }}>{`type://HttpRequest`}</a>{` fluently. `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/3110"
          }}>{`#3110`}</a></p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java"
          }}>{`// Creates a POST HttpRequest whose URI is "/foo?q=bar"
// with headers "cookie: name=value" and "authorization: value" and a JSON body.
HttpRequest.builder()
           .post("/{resource}")
           .pathParam("resource", "foo")
           .queryParam("q", "bar")
           .cookie(Cookie.of("name", "value"))
           .header("authorization", "value")
           .content(MediaType.JSON, "{\\"foo\\":\\"bar\\"}"));
// You can also use WebClient.prepare().
WebClient client = ...
client.prepare()
      .post("/{resource}")
      .pathParam("resource", "foo")
      .queryParam("q", "bar")
      .cookie(Cookie.of("name", "value"))
      .header("authorization", "value")
      .content(MediaType.JSON, "{\\"foo\\":\\"bar\\"}")
      .execute();
`}</code></pre>
      </li>
      <li parentName="ul">
        <p parentName="li">{`You can now easily handle cookies by applying `}<a parentName="p" {...{
            "href": "type://CookieClient#newDecorator():https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/cookie/CookieClient.html#newDecorator()"
          }}>{`type://CookieClient#newDecorator()`}</a>{`. `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/2637"
          }}>{`#2637`}</a>{` `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/3118"
          }}>{`#3118`}</a></p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java"
          }}>{`WebClient client = WebClient.builder()
                            .factory(factory)
                            .decorator(CookieClient.newDecorator())
                            .build();
client.get(...); // The cookies that are received from the origin server
                 // are added to the request headers.
`}</code></pre>
      </li>
      <li parentName="ul">
        <p parentName="li">{`You can now use the custom Thrift protocol by using `}<a parentName="p" {...{
            "href": "type://ThriftProtocolFactoryProvider:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/thrift/ThriftProtocolFactoryProvider.html"
          }}>{`type://ThriftProtocolFactoryProvider`}</a>{` and SPI. `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/3183"
          }}>{`#3183`}</a></p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java"
          }}>{`public class TTupleFactoryProvider extends ThriftProtocolFactoryProvider {
    @Override
    public Set<ThriftProtocolFactoryProvider.Entry> entries() {
        return ImmutableSet.of(new ThriftProtocolFactoryProvider.Entry(
                SerializationFormat.of("ttuple"), new TTupleProtocol.Factory()));
    }
}
`}</code></pre>
      </li>
      <li parentName="ul">
        <p parentName="li">{`You can now collect more detailed DNS metrics. `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/1887"
          }}>{`#1887`}</a>{` `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/2935"
          }}>{`#2935`}</a></p>
        <ul parentName="li">
          <li parentName="ul"><inlineCode parentName="li">{`armeria.client.dns.queries#count{...,result=success}`}</inlineCode></li>
          <li parentName="ul"><inlineCode parentName="li">{`armeria.client.dns.queries#count{...,result=failure}`}</inlineCode></li>
          <li parentName="ul"><inlineCode parentName="li">{`armeria.client.dns.queries.written#count{...}`}</inlineCode></li>
          <li parentName="ul"><inlineCode parentName="li">{`armeria.client.dns.queries.cancelled#count{...}`}</inlineCode></li>
          <li parentName="ul"><inlineCode parentName="li">{`armeria.client.dns.queries.redirected#count{...}`}</inlineCode></li>
          <li parentName="ul"><inlineCode parentName="li">{`armeria.client.dns.queries.cnamed#count{...}`}</inlineCode></li>
          <li parentName="ul"><inlineCode parentName="li">{`armeria.client.dns.queries.noanswer#count{...}`}</inlineCode></li>
        </ul>
      </li>
      <li parentName="ul">
        <p parentName="li">{`You can now customize the `}<a parentName="p" {...{
            "href": "type://HealthCheckService:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/healthcheck/HealthCheckService.html"
          }}>{`type://HealthCheckService`}</a>{` when using Spring integration. `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/3144"
          }}>{`#3144`}</a></p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java"
          }}>{`@Bean
public HealthCheckServiceConfigurator healthCheckServiceConfigurator() {
    return builder -> builder.updatable(true);
}
`}</code></pre>
      </li>
      <li parentName="ul">
        <p parentName="li">{`You can now use `}<a parentName="p" {...{
            "href": "type://RequestHeaders#acceptLanguage()"
          }}>{`type://RequestHeaders#acceptLanguage()`}</a>{` to choose language. `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/3177"
          }}>{`#3177`}</a>{` `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/3179"
          }}>{`#3179`}</a></p>
      </li>
    </ul>
    <h2 {...{
      "id": "-improvements",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#-improvements",
        "aria-label": " improvements permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`📈 Improvements`}</h2>
    <ul>
      <li parentName="ul">{`Various improvements for `}<a parentName="li" {...{
          "href": "type://DocService:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/docs/DocService.html"
        }}>{`type://DocService`}</a>{`. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/3149"
        }}>{`#3149`}</a>{` `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/3150"
        }}>{`#3150`}</a>{` `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/3167"
        }}>{`#3167`}</a>{` `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/3188"
        }}>{`#3188`}</a></li>
    </ul>
    <h2 {...{
      "id": "️-bug-fixes",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#%EF%B8%8F-bug-fixes",
        "aria-label": "️ bug fixes permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`🛠️ Bug fixes`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "type://HealthCheckedEndpointGroup#endpoints()"
        }}>{`type://HealthCheckedEndpointGroup#endpoints()`}</a>{` now returns healthy endpoints properly even when
`}<a parentName="li" {...{
          "href": "type://EndpointGroup#orElse(EndpointGroup):https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/endpoint/EndpointGroup.html#orElse(com.linecorp.armeria.client.endpoint.EndpointGroup)"
        }}>{`type://EndpointGroup#orElse(EndpointGroup)`}</a>{` is used. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/3181"
        }}>{`#3181`}</a></li>
      <li parentName="ul"><inlineCode parentName="li">{`ServletRequest.getProtocol()`}</inlineCode>{` now returns the proper value when using `}<a parentName="li" {...{
          "href": "type://TomcatService:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/tomcat/TomcatService.html"
        }}>{`type://TomcatService`}</a>{` and
`}<a parentName="li" {...{
          "href": "type://JettyService:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/jetty/JettyService.html"
        }}>{`type://JettyService`}</a>{`. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/3194"
        }}>{`#3194`}</a></li>
      <li parentName="ul">{`The route decorators are now evaluated in the reverse order they applied. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/3160"
        }}>{`#3160`}</a>{` `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/3166"
        }}>{`#3166`}</a>
        <ul parentName="li">
          <li parentName="ul">{`The purpose of this change is to make sure we provide a consistent experience when decorating a service.
For example, when we decorate a service like the following:`}
            <pre parentName="li"><code parentName="pre" {...{
                "className": "language-java"
              }}>{`myService
  .decorate(decoratorA)
  .decorate(decoratorB);
`}</code></pre>
            {`we expect `}<inlineCode parentName="li">{`decoratorA`}</inlineCode>{` decorates `}<inlineCode parentName="li">{`myService`}</inlineCode>{` and `}<inlineCode parentName="li">{`decoratorB`}</inlineCode>{` decorates `}<inlineCode parentName="li">{`decoratorA`}</inlineCode>{`.
Route decorators were breaking this expectation.`}</li>
        </ul>
      </li>
      <li parentName="ul">{`You now get the FORBIDDEN status if your service does not handle preflight requests regardless of
route decorators. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/3152"
        }}>{`#3152`}</a></li>
      <li parentName="ul">{`A gRPC `}<inlineCode parentName="li">{`ServerCall`}</inlineCode>{` is now closed exactly only once. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/3153"
        }}>{`#3153`}</a></li>
      <li parentName="ul">{`You no longer see `}<inlineCode parentName="li">{`AnnotatedConnectException`}</inlineCode>{` when the `}<a parentName="li" {...{
          "href": "type://Endpoint:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/Endpoint.html"
        }}>{`type://Endpoint`}</a>{` is created with an
IPv6 scope ID. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/3158"
        }}>{`#3158`}</a>{` `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/3178"
        }}>{`#3178`}</a></li>
      <li parentName="ul">{`Armeria server does not reject the request path whose first segment includes a colon anymore. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/3154"
        }}>{`#3154`}</a></li>
    </ul>
    <h2 {...{
      "id": "️-deprecations",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#%EF%B8%8F-deprecations",
        "aria-label": "️ deprecations permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`🏚️ Deprecations`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "type://CircuitBreakerClient#newPerHostAndMethodDecorator(BiFunction,CircuitBreakerRule):https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/circuitbreaker/CircuitBreakerClient.html#newPerHostAndMethodDecorator(java.util.function.BiFunction,com.linecorp.armeria.client.circuitbreaker.CircuitBreakerRule)"
        }}>{`type://CircuitBreakerClient#newPerHostAndMethodDecorator(BiFunction,CircuitBreakerRule)`}</a>{` is now deprecated.
`}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/3135"
        }}>{`#3135`}</a>
        <ul parentName="li">
          <li parentName="ul">{`Use `}<a parentName="li" {...{
              "href": "type://CircuitBreakerClient#newDecorator(CircuitBreakerMapping,CircuitBreakerRule):https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/circuitbreaker/CircuitBreakerClient.html#newDecorator(com.linecorp.armeria.client.circuitbreaker.CircuitBreakerMapping,com.linecorp.armeria.client.circuitbreaker.CircuitBreakerRule)"
            }}>{`type://CircuitBreakerClient#newDecorator(CircuitBreakerMapping,CircuitBreakerRule)`}</a>{` with the customized
`}<a parentName="li" {...{
              "href": "type://CircuitBreakerMapping:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/circuitbreaker/CircuitBreakerMapping.html"
            }}>{`type://CircuitBreakerMapping`}</a>{` using `}<a parentName="li" {...{
              "href": "type://CircuitBreakerMapping#builder():https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/circuitbreaker/CircuitBreakerMapping.html#builder()"
            }}>{`type://CircuitBreakerMapping#builder()`}</a>{`.`}</li>
        </ul>
      </li>
      <li parentName="ul">{`The response timeout and max total attempts setters in `}<a parentName="li" {...{
          "href": "type://RetryingClientBuilder:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/retry/RetryingClientBuilder.html"
        }}>{`type://RetryingClientBuilder`}</a>{` are now deprecated.
`}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/3128"
        }}>{`#3128`}</a>{` `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/3145"
        }}>{`#3145`}</a>
        <ul parentName="li">
          <li parentName="ul">{`The static factory methods that take those parameters in `}<a parentName="li" {...{
              "href": "type://RetryingClient:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/retry/RetryingClient.html"
            }}>{`type://RetryingClient`}</a>{` are now deprecated as well.`}</li>
          <li parentName="ul">{`Use `}<a parentName="li" {...{
              "href": "type://RetryConfigMapping:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/retry/RetryConfigMapping.html"
            }}>{`type://RetryConfigMapping`}</a>{` and `}<a parentName="li" {...{
              "href": "type://RetryConfig:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/retry/RetryConfig.html"
            }}>{`type://RetryConfig`}</a>{`.`}</li>
        </ul>
      </li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "type://Route#apply(RoutingContext):https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/Route.html#apply(com.linecorp.armeria.server.RoutingContext)?full"
        }}>{`type://Route#apply(RoutingContext)?full`}</a>{` is deprecated. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/3152"
        }}>{`#3152`}</a>
        <ul parentName="li">
          <li parentName="ul">{`Use `}<a parentName="li" {...{
              "href": "type://Route#apply(RoutingContext,boolean):https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/Route.html#apply(com.linecorp.armeria.server.RoutingContext,boolean)?full"
            }}>{`type://Route#apply(RoutingContext,boolean)?full`}</a>{`.`}</li>
        </ul>
      </li>
      <li parentName="ul">{`The constructor of `}<a parentName="li" {...{
          "href": "type://PrometheusExpositionService:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/metric/PrometheusExpositionService.html"
        }}>{`type://PrometheusExpositionService`}</a>{` is now deprecated. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/3081"
        }}>{`#3081`}</a>
        <ul parentName="li">
          <li parentName="ul">{`Use `}<a parentName="li" {...{
              "href": "type://PrometheusExpositionService#of(CollectorRegistry):https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/metric/PrometheusExpositionService.html#of(io.prometheus.client.CollectorRegistry)"
            }}>{`type://PrometheusExpositionService#of(CollectorRegistry)`}</a>{`.`}</li>
        </ul>
      </li>
    </ul>
    <h2 {...{
      "id": "️-breaking-changes",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#%EF%B8%8F-breaking-changes",
        "aria-label": "️ breaking changes permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`☢️ Breaking changes`}</h2>
    <ul>
      <li parentName="ul">{`Route decorators are now evaluated in the reverse order they applied. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/3160"
        }}>{`#3160`}</a>{` `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/3166"
        }}>{`#3166`}</a>
        <ul parentName="li">
          <li parentName="ul">{`The purpose of this change is to make sure we provide a consistent experience when decorating a service.
For example, when we decorate a service like the following:`}
            <pre parentName="li"><code parentName="pre" {...{
                "className": "language-java"
              }}>{`myService
  .decorate(decoratorA)
  .decorate(decoratorB);
`}</code></pre>
            {`we expect `}<inlineCode parentName="li">{`decoratorA`}</inlineCode>{` decorates `}<inlineCode parentName="li">{`myService`}</inlineCode>{` and `}<inlineCode parentName="li">{`decoratorB`}</inlineCode>{` decorates `}<inlineCode parentName="li">{`decoratorA`}</inlineCode>{`.
Route decorators were breaking this expectation.`}</li>
        </ul>
      </li>
    </ul>
    <h2 {...{
      "id": "-dependencies",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#-dependencies",
        "aria-label": " dependencies permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`⛓ Dependencies`}</h2>
    <ul>
      <li parentName="ul">{`Dropwizard 2.0.13 → 2.0.16`}</li>
      <li parentName="ul">{`Fastutil 8.4.2 → 8.4.3`}</li>
      <li parentName="ul">{`gRPC 1.33.0 → 1.33.1`}</li>
      <li parentName="ul">{`grpc-kotlin-stub 0.2.0 → 0.2.1`}</li>
      <li parentName="ul">{`Dropwizard Metrics 4.1.13 → 4.1.15`}</li>
      <li parentName="ul">{`Jackson 2.11.2 → 2.12.0`}</li>
      <li parentName="ul">{`JCTools 3.1.0 → 3.2.0`}</li>
      <li parentName="ul">{`javax.annotation-api 1.3.2`}
        <ul parentName="li">
          <li parentName="ul">{`jakarta-annotation-api 2.0 has been released with a breaking change so we use javax.annotation-api instead.`}</li>
        </ul>
      </li>
      <li parentName="ul">{`Micrometer 1.5.5 → 1.6.1`}</li>
      <li parentName="ul">{`Netty 4.1.53.Final → 4.1.54.Final`}</li>
      <li parentName="ul">{`BouncyCastle 1.66 → 1.67`}</li>
      <li parentName="ul">{`Reactor 3.3.10.RELEASE → 3.4.0`}</li>
      <li parentName="ul">{`Spring Boot 2.3.4.RELEASE → 2.4.0`}</li>
      <li parentName="ul">{`Spring 5.2.9.RELEASE → 5.3.1`}</li>
      <li parentName="ul">{`Tomcat 9.0.39 → 9.0.40`}</li>
    </ul>
    <h2 {...{
      "id": "-thank-you",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#-thank-you",
        "aria-label": " thank you permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`🙇 Thank you`}</h2>
    <ThankYou usernames={['anuraaga', 'codefromthecrypt', 'ghkim3221', 'haithamgabr', 'heowc', 'ikhoon', 'jrhee17', 'KarboniteKream', 'kojilin', 'masonshin', 'minwoox', 'okue', 'perlun', 'rolandblain', 'techno', 'tobias-', 'trustin', 'tumile', 'Ubehebe', 'wickedev', 'windmeup']} mdxType="ThankYou" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      